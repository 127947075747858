import { Component, OnInit, Input, AfterViewInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { NavigationEnd, NavigationStart, Router,Event as NavigationEvent } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'partial-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit{

  @Input("classCustom") classCustom = "";
  @Input("template") template       = "default";
  @ViewChild("content") content:any; 
  @ViewChild("close") close:any; 
  @ViewChild("overlay") overlay:any;   
  public show              = false;
  public menuCategoria:any = [];

  constructor(
    private dataService: DataService
  ){ 

    this.menuCategoria = this.dataService.getCategoriasMenu();

  }
  /**
   * 
   * Open
   * 
   */
  _open(){

    if(this.show){

      this.content.nativeElement.classList.remove("slide-right");
      this.content.nativeElement.classList.add("slide-left");

      setTimeout(() => {
        this.content.nativeElement.classList.remove("show");
      },200);
      this.overlay.nativeElement.classList.remove("show");
      this.close.nativeElement.classList.remove("show");
      this.show = false;

    }else{

      this.content.nativeElement.classList.add("slide-right");

      setTimeout(() => {
        this.content.nativeElement.classList.add("show");
      },200);

      this.content.nativeElement.classList.remove("slide-left"); 
      this.overlay.nativeElement.classList.add("show");
      this.close.nativeElement.classList.add("show");
      this.show = true;

    }

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}

}
