<div class="product-item {{classCustom}}">
    <div class="capa" (click)="_click()" *ngIf="lazy">
      <a *ngIf="produto.foto != null" itemprop="url" title="Saiba mais sobre: {{produto.nome}}">
        <img width="100%"  defaultImage="/images/produto/sem-foto/default-capa.jpg" [lazyLoad]="produto.foto" itemprop="image" [alt]="produto.nome" class="img-fluid" /> 
      </a>  
      <a *ngIf="produto.foto == null" itemprop="url" title="Saiba mais sobre: {{produto.nome}}">  
        <img width="100%" itemprop="image" src="/images/produto/sem-foto/default-capa.jpg" alt="Sem Foto" class="img-fluid" />
      </a>   
    </div>
    <div class="capa" (click)="_click()" *ngIf="!lazy">
      <a *ngIf="produto.foto != null" itemprop="url" title="Saiba mais sobre: {{produto.nome}}">
        <img width="100%" [src]="produto.foto" itemprop="image" [alt]="produto.nome" class="img-fluid" /> 
      </a>  
      <a *ngIf="produto.foto == null" itemprop="url" title="Saiba mais sobre: {{produto.nome}}">  
        <img width="100%" itemprop="image" src="/images/produto/sem-foto/default-capa.jpg" alt="Sem Foto" class="img-fluid" />
      </a>
    </div>
    <div class="content">
      <div class="content-body">
        <h4>{{produto.nome}}</h4>
        <article *ngIf="produto.descricao_listagem !== null && produto.descricao_listagem !== ''" [innerHtml]="produto.descricao_listagem | safeHtml"></article>
        <div class="info">
          <div class="item" *ngIf="produto.duracao != null">
            <i class="material-icons">schedule</i> 
            <span>{{produto.duracao | formatDuracao}} (duração aproximada)</span>  
          </div>
          <div class="item" *ngIf="produto.dias.length > 0">
            <i class="material-icons">today</i>
            <span>{{produto.dias | formatDias}}</span>
          </div> 
          <div class="item" *ngIf="produto.tags.length > 0">
            <div class="tag tag-2" *ngFor="let t of produto.tags">{{t.titulo}}</div>
          </div>   
        </div>
      </div>
      <div class="linha"></div>
      <div class="footer">
        <div class="box">
          <a href='javascript::void();' (click)="_click()" alt="Ver produto" class="link-product">
            <span>Saiba mais</span>
          </a>    
        </div>
        <div class="box">
          <div class="tariff">
            <produto-item-valor [servico]="produto" [valor]="produto.menor_tarifa"></produto-item-valor>
          </div>
        </div>   
      </div> 
      <div class="installments" *ngIf="produto.mostrar_parcelas">
        <span>Em até <strong>{{produto.parcelas}}x</strong> sem juros</span> 
     </div>     
    </div>    
</div>   