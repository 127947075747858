<div class="module module-product-offer">
      <div class="module-title">
          <div class="container">
              <h2>{{data.titulo}}</h2>
              <h3>{{data.subtitulo}}</h3>
          </div>  
      </div>
      <div class="module-content">
            <div class="container">
                  <div class="products slider-items slick-padding-mobile" #slider>  
                        <div class="slider-item" *ngFor="let p of data.classificacao">  
                              <produto-classificacao-item [data]="p"></produto-classificacao-item>
                        </div>      
                  </div> 
                  <div class="slide-navigate-item prev" (click)="prev()">
                        <img width="15px" height="15px" src="/images/icons/prev.svg" alt="Prev" />
                  </div>
                  <div class="slide-navigate-item next" (click)="next()">
                        <img width="15px" height="15px" src="/images/icons/next.svg" alt="Next" />
                  </div>  
            </div>
      </div>     
</div>