import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router,Event as NavigationEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss']
})
export class SidebarMenuComponent implements OnInit {

  public show                 = false; 
  @Input("showMenu") showMenu = new EventEmitter();
  public currentUrl:any       = "";
  public categorias:any       = [];

  constructor(
    private router: Router,
    private data: DataService
  ){}   

  /***
   * 
   * On click
   * 
   */
  _click(){
    
    this.show = false;

  }
  /**
   * 
   * On data
   * 
   */
  onData(){

    this.showMenu.subscribe(data => {

      this.show = true;

    });

    this.router.events.forEach((event: NavigationEvent) => {
    
      if(event instanceof NavigationEnd) {

        this.show = false;
                
      }

    });

    let self = this;

    const navEndEvents = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    );
    navEndEvents.subscribe((event:any) => {
      
      self.currentUrl = event.url; 

    }); 

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.onData();
    this.categorias = this.data.getCategoriasMenu();
  }


}
