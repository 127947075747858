import { Component, Input, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'partial-compra-segura',
  templateUrl: './partial-compra-segura.component.html',
  styleUrls: ['./partial-compra-segura.component.scss']
})
export class PartialCompraSeguraComponent implements OnInit {

  @Input("classCustom") classCustom = null;
  public color = null;
  public configuracao = null;

  constructor(
    public dataService: DataService
  ) { }

/**
   * 
   * Set color
   * 
   */
 setColor(){

  if(this.configuracao != null){

    this.color = this.configuracao.cor_2;

  }

}
/**
 * 
 * Init
 * 
 */
ngOnInit():void{ 
  this.configuracao = this.dataService.getConfiguracao();
  this.setColor();
}
}
