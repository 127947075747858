import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'trip-advisor-modulo',
  templateUrl: './trip-advisor-modulo.component.html',
  styleUrls: ['./trip-advisor-modulo.component.scss']
})
export class TripAdvisorModuloComponent implements OnInit {

  @Input("data") data;
  @Input("template") template = null;
  @Input("classCustom") classCustom;
  
  constructor() { }

  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}

}
