<div class="contato-footer">
      <div class="item">
            <modulo posicao="endereco" template="footer"></modulo>
      </div>
      <div class="item">
          <modulo posicao="contato" template="footer"></modulo>
      </div>
      <div class="item">
          <modulo posicao="contato" template="email"></modulo>
      </div>    
</div>    