import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProdutoClassificacaoItemComponent } from '../../../components/produto/produto-classificacao-item/produto-classificacao-item.component';
import { PartialModule } from '../../partial/partial.module';

@NgModule({
  declarations: [
    ProdutoClassificacaoItemComponent
  ],
  exports: [
    ProdutoClassificacaoItemComponent
  ],
  imports: [
    CommonModule,
    PartialModule
  ]
})
export class ProdutoClassificacaoItemModule { }
