<div class="topo-partial">
      <div class="item ul-phones">
        <modulo posicao="contato" template="topo"></modulo> 
      </div>
      <div class="item">  
      <ul>
          <li class="li-item">
              <a routerLink="/blog" title="Blog">
                Blog
              </a> 
          </li> 
          <li class="divisor-circle">
              <span></span>
          </li>
          <li class="li-item">
              <a routerLink="/protocolos" title="Protocolos">
                {{'Protocolos' | translate}}
              </a> 
          </li> 
          <li class="divisor-circle">
              <span></span>
          </li> 
          <li class="li-item">
              <a routerLink="/quem-somos/historia" title="Conheça a Paraty">
                {{'Conheça a Paraty' | translate}}
              </a> 
          </li> 
          <li class="divisor-circle">
              <span></span>
          </li> 
          <li class="li-item">
                <a routerLink="/quem-somos" title="Quem Somos">
                    {{'Quem Somos' | translate}}
                </a> 
          </li>
          <li class="divisor-circle">
              <span></span>
          </li> 
          <li class="li-item">
              <a routerLink="/contato" title="Contato">
                {{'Contato' | translate}}
              </a> 
          </li> 
          <li class="divisor-circle">
              <span></span>
          </li> 
          <li>
              <btn-usuario-login></btn-usuario-login> 
          </li> 
          <li class="li-cart-total">
              <btn-cart></btn-cart> 
          </li>   
          <li>
            <lg-list></lg-list>
          </li>  
          <li class="li-menu">  
            <partial-menu classCustom="mobile"></partial-menu>
          </li>    
      </ul> 
      </div>   
  </div>    
  