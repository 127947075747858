<div class="produto-item-three">
      <div class="row">
            <div class="col-md-3">
                  <figure *ngIf="produto.foto != null">
                        <img width="100%"  defaultImage="/images/produto/sem-foto/default-capa.jpg" [lazyLoad]="produto.foto" itemprop="image" [alt]="produto.nome" class="img-fluid" /> 
                  </figure> 
                  <figure *ngIf="produto.foto == null">  
                        <img width="100%" itemprop="image" src="/images/produto/sem-foto/default-capa.jpg" alt="Sem Foto" class="img-fluid" />
                  </figure>   
            </div>
            <div class="col-md-6">  
                  <div class="product-content">
                        <h3>{{produto.nome}}</h3>
                        <h4>{{produto.categoria_titulo}}</h4>
                        <div class="info">
                              <div class="item" *ngIf="produto.duracao != null">
                                <i class="material-icons">schedule</i> 
                                <span>{{produto.duracao | formatDuracao}} (duração aproximada)</span>  
                              </div>
                              <div class="item" *ngIf="produto.dias.length > 0">
                                <i class="material-icons">today</i>
                                <span>{{produto.dias | formatDias}}</span>
                              </div> 
                              <div class="item" *ngIf="produto.tags.length > 0">
                                <div class="tag tag-2" *ngFor="let t of produto.tags">{{t.titulo}}</div>
                              </div>   
                        </div>
                  </div> 
            </div>
            <div class="col-md-3 col-center">
                  <div class="footer">
                        <div class="box">
                              <div class="tariff">
                                <produto-item-valor classCustom="value-three" [servico]="produto" [valor]="produto.menor_tarifa"></produto-item-valor>
                              </div>
                        </div>
                        <div class="box">
                          <a href='javascript::void();' (click)="_click()" alt="Ver produto" class="btn-one link-product">
                            <span>Saiba mais</span>
                          </a>    
                        </div>   
                  </div> 
            </div>      
      </div>  
</div>      