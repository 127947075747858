<div class="menu-partial {{classCustom}} template-{{template}}">
    <button type="button" class="btn-open" (click)="_open()">
        <i class="material-icons">menu</i>
    </button>  
    <div class="close" #close (click)="_open()">  
        <i class="material-icons">close</i>
    </div>    
    <div class="content slide-right" #content> 
        <ul>
            <li *ngFor="let item of menuCategoria">
                <a routerLink="servicos/{{item.apelido}}" title="Passeios">
                    <img [src]="item.icone" [alt]="item.titulo" width="20px" height="20px" />
                    <span>{{item.titulo}}</span>
                </a>    
            </li>
            <li>
                <a routerLink="grupos-e-eventos" title="Eventos">
                    <img src="/images/icons/eventos.svg" alt="Grupos e Eventos" width="20px" height="20px" />
                    <span>Grupos e Eventos</span>
                </a>    
            </li>
        </ul> 
    </div>   
</div> 
<div class="overlay" #overlay></div>   
