import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'menu-footer-partial',
  templateUrl: './menu-footer.component.html',
  styleUrls: ['./menu-footer.component.scss']
})
export class MenuFooterComponent implements OnInit {

  constructor() { }

  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}

}
