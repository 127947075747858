import { Pipe, PipeTransform } from '@angular/core';
import { DataService } from '../services/data.service';
import { TranslateService } from '../services/translate.service';

@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {

  constructor(
    private data: DataService,
    private translate: TranslateService
  ){}

  /**
   * 
   * 
   * @param value 
   * @param args 
   * 
   */
  transform(value:any,args:any):any{
    
    let linguagem = this.data.getLinguagem();

    return linguagem == "pt-BR" ? value : this.translate.getTraducao(value,linguagem)

  }

}
