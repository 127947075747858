import { Component, Input, OnInit, ViewChild } from '@angular/core';

declare var $:any;

@Component({
  selector: 'modulo-produto-experiencia',
  templateUrl: './produto-experiencia.component.html',
  styleUrls: ['./produto-experiencia.component.scss']
})
export class ProdutoExperienciaComponent implements OnInit {

  @Input("data") data:any = {};
  @Input("template") template:any = {};

  @ViewChild("slider") slider: any;
  public products:any = [];
  
  constructor(){ }

  /**
   * 
   * Set data
   * 
   */
   setData(){

    this.products = [{
      image: "/images/produtos/experiencia/01.jpg",
      name: "Aventura"
    },{
      image: "/images/produtos/experiencia/02.jpg",
      name: "Família"
    },{
      image: "/images/produtos/experiencia/03.jpg",
      name: "Praia"
    },{
      image: "/images/produtos/experiencia/04.jpg",
      name: "Cultural"
    }];

  }
  /***
   * 
   * Inicializa o Slider
   * 
   */
   initSlider(){

    $(this.slider.nativeElement).slick({ 
      infinite: true,
      slidesToShow: 3,
      dots: false, 
      arrows: false,
      centerMode: false,
      centerPadding: '0',
      responsive: [{
          breakpoint: 1224,
          settings: {
            slidesToShow: 3,  
          }
      },{
          breakpoint: 1024,
          settings: {
          slidesToShow: 2,
        }
      },
      {
          breakpoint: 768,
          settings: {
              slidesToShow: 1,

          }
      },
      {
          breakpoint: 540,
          settings: {
            slidesToShow: 1,
            centerPadding: '0',
          }
      }
    ]
    
    }); 
    $(this.slider.nativeElement).slick('refresh'); 
    

  }
  /**
   * 
   * Next
   * 
   */
  prev(){ 

    $(this.slider.nativeElement).slick("slickPrev");  

  }
  /**
   * 
   * Next
   * 
   */
  next(){

    $(this.slider.nativeElement).slick("slickNext"); 

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit(): void {
    this.setData();
  }
  /**
   * 
   * After view init
   * 
   */
   ngAfterViewInit(): void {
    this.initSlider();
  }

}
