import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'modulo-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class ModuloLogo implements OnInit {

  @Input("data") data;
  public logo = null;
  @Input("classCustom") classCustom = "";
  @Input("template") template = null; 
  public width       = 150;
  public widthMobile = 100;
  public widthLogo   = 150; 

  constructor(private router: Router){}  
  /**
   * 
   * Router
   * 
   */
  _click(){

    this.router.navigateByUrl("/");

  }
  /***
   * 
   * Logo
   * 
   */
  setLogo(){ 

    this.logo = "/images/modulo/logo/"+this.data.params.imagem; 
 
  }
  /**
   * 
   * Set width
   * 
   */ 
  setWidth(){

    if(typeof(this.data.params.tamanho_logo) != "undefined"){

      this.width = this.data.params.tamanho_logo;   

    }
    if(typeof(this.data.params.tamanho_logo_mobile) != "undefined"){

      this.widthMobile = this.data.params.tamanho_logo_mobile;     

    }

    if(window.innerWidth >= 700){
      this.widthLogo = this.width;
    }else{
      this.widthLogo = this.widthMobile;     
    }

  }
  /***
   * 
   * Resize
   * 
   */
  @HostListener('window:resize',['$event'])
  onResize(event) {
    
    if(event.target.innerWidth >= 700){
      this.widthLogo = this.width;
    }else{
      this.widthLogo = this.widthMobile;     
    }

  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){
    this.setLogo();
    this.setWidth();
  }

}
