import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'produto-embarcacao-item',
  templateUrl: './produto-embarcacao-item.component.html',
  styleUrls: ['./produto-embarcacao-item.component.scss']
})
export class ProdutoEmbarcacaoItemComponent implements OnInit {

  @Input("data") data:any = null;

  constructor(
    private router: Router
  ){}

  /**
   * 
   * On Click
   * 
   */
   _onClick(){

    this.router.navigateByUrl("/produto");

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit(): void {
  }

}
