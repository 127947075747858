import { Directive, ElementRef, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';

@Directive({
  selector: '[bg-1]'
})
export class Bg1Directive implements OnInit {

  public configuracao = null;

  constructor(
    private el: ElementRef,
    private data: DataService
  ){

  }
  /**
   * 
   * Set
   * 
   */
  set(){

    this.el.nativeElement.style.backgroundColor = this.configuracao.cor_1; 

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit(): void {
    this.configuracao = this.data.getConfiguracao();
    this.set();
  }

}
