<nav class="breadcrumbs">
  <div class="container">
    <ul>
      <li *ngFor="let i of itens;let l=last;">
        <a [routerLink]="i.routerLink" [title]="i.text" [ngClass]="{'active': l}" (click)="_click(i.routerLink,l)">
          {{i.text}}
        </a>    
      </li>    
    </ul> 
  </div>
</nav>