<div class="dm-modal-confirm">
  <div class="dm-modal-confirm-title">
    <h4>Atenção</h4>
  </div>  
  <div class="dm-modal-confirm-content">
    <article class="message alert alert-info" [innerHtml]="data.message">
    </article>    
  </div>
  <div class="dm-modal-confirm-footer">
    <button type="modal" mat-raised-button class="nao" (click)="close()">
      NÃO
    </button> 
    <button type="modal" mat-raised-button class="sim" (click)="send()">
      SIM
    </button> 
  </div>   
</div>
    