import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'modulo-produto-busca',
  templateUrl: './produto-busca.component.html',
  styleUrls: ['./produto-busca.component.scss']
})
export class ProdutoBuscaComponent implements OnInit {

  @Input("data") data:any = null;
  public dataItem:any     = {};
  public loader           = false;
  public _data            = null;

  constructor(
    private api: ApiService
  ){}
  /**
   * 
   * Get search
   * 
   */
  getData(){

    try{

      this.loader = true;

      this.api.produto().search(this.dataItem).subscribe(response => {

        this.loader = false;

        if(response.status == 1){
          this._data = response.data.produtos;
        }else{
          this._data = [];
        }

      },(response) => {

        this.loader = false;
        this._data  = [];

      });

    }catch(e){

      this.loader = false;


    }


  }
  /**
   * 
   * Search
   * 
   */
  _search(){

    if(this.dataItem.termo.length >= 3){

      this.getData();

    }else{
      this._data = null;
    }

  }
  /**
   * 
   * Init data item
   * 
   */
  initDataItem(){

    this.dataItem = {
      termo: "",
      termo_categoria: true,
      produto_categoria_id: null
    }

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.initDataItem();
  }

}
