import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputCheckComponent),
  multi: true,
};

@Component({
  selector: 'input-check',
  templateUrl: './input-check.component.html',
  styleUrls: ['./input-check.component.scss'],
  providers: [
    ACCESSOR
  ]
})
export class InputCheckComponent extends DefaultValueAccessor implements OnInit{

  @Input("ngModel") value:any = false;
  @Input("label") label       = null;
  @Output("change") change    = new EventEmitter();

  constructor(
    private renderer2: Renderer2,
    private el: ElementRef
  ){ 
    
    super(renderer2,el,false);
  
  }

  /**
   * 
   * Click
   * 
   */
  _click(){

    this.value = this.value == true ? false : true;

    super.writeValue(this.value);
    this.onChange(this.value); 
 
    this.change.emit(this.value); 

  }
  /***
   * 
   * Init
   * 
   **/
  ngOnInit():void{}

}
