import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'copyright',
  templateUrl: './copyright.component.html',
  styleUrls: ['./copyright.component.scss']
})
export class CopyrightComponent implements OnInit {

  public config = null;

  constructor(
    private data: DataService
  ){}
  /**
   * 
   * Set data
   * 
   */
  setData(){

    this.config = this.data.getConfiguracao();

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.setData();
  }

}
