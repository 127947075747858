import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'topo-partial',
  templateUrl: './topo-partial.component.html',
  styleUrls: ['./topo-partial.component.scss']
})
export class TopoPartialComponent implements OnInit {

  constructor(){}

  /**
   * 
   * Open menu
   * 
   */
  _openLogin(){

    alert("open login")

  }
  /**
   * 
   * Init data item
   * 
   */
  ngOnInit():void{}

}
