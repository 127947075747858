<div class="module-whatsapp fixo" (click)="open()" *ngIf="template == 'fixo' || template == null">
    <div class="content">
        <span>Chama no <br><strong>WhatsApp!</strong></span>
        <div class="icon">
            <i class="fa-brands fa-whatsapp"></i>
        </div>  
    </div> 
    <div class="content mobile">
        <div class="icon">
            <i class="fa-brands fa-whatsapp"></i>
        </div> 
    </div>     
</div>  
<div class="module-whatsapp servico" *ngIf="template == 'servico'">
    <span class="title">Fale pelo WhatsApp:</span>
    <button tpe="button" class="btn-two btn-icon center" (click)="open()">
        <div class="icon">
            <i class="fa-brands fa-whatsapp"></i>
        </div>
        <span>WhatsApp</span>
    </button>
</div>  
<div class="default-list" *ngIf="template == 'list'" (click)="open()">
    <div class="icon">
        <i class="fa-brands fa-whatsapp"></i>
    </div>   
    <div class="content">
        <span class="title">Fale pelo WhatsApp:</span>
        <a href="#">{{data.params.telefone}}</a>  
    </div>
</div>  
