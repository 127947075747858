import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnUsuarioLoginComponent } from 'src/app/components/btns/btn-usuario-login/btn-usuario-login.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { UsuarioLoginModalModule } from '../../minha-conta/usuario-login-modal/usuario-login-modal.module';
import { PipeModule } from '../../pipe/pipe.module';

@NgModule({
  declarations:[
    BtnUsuarioLoginComponent
  ],
  exports:[
    BtnUsuarioLoginComponent
  ],
  imports:[
    CommonModule,
    LazyLoadImageModule,
    UsuarioLoginModalModule,
    PipeModule
  ],
  providers:[]
})
export class BtnUsuarioLoginModule { }
