<div class="module module-products">
      <div class="module-title">
          <div class="container">
              <h2>{{data.titulo}}</h2>
              <h3>{{data.subtitulo}}</h3>
          </div>    
      </div>
      <div class="module-content">
          <div class="container">
                <ul class="menu-custom"> 
                  <li *ngFor="let i of tabs;let y=index;" [ngClass]="{'active': y == tabSelect}" (click)="selectTab(y)">
                    <span>{{i.title | translate}}</span>
                  </li>    
                </ul> 
                <div class="menu-content">
                    <div class="items" *ngFor="let tab of tabs;let index=index;"> 
                        <div class="item animated fadeIn" [ngClass]="{'hide': !showItem}" *ngIf="tabSelect == index">  
                            <div class="products slider-items slick-padding-mobile" #slider>  
                                <div class="slider-item" *ngFor="let p of tab.produtos">    
                                    <produto-item classCustom="transfer" [data]="p"></produto-item>    
                                </div>      
                            </div>
                            <div class="slide-navigate-item prev" (click)="prev()">
                                <img width="15px" height="15px" src="/images/icons/prev.svg" alt="Prev" />
                            </div>
                            <div class="slide-navigate-item next" (click)="next()">
                                <img width="15px" height="15px" src="/images/icons/next.svg" alt="Next" />
                            </div> 
                        </div>
                    </div>
                </div>
          </div>
      </div> 
      <div class="center">
          <a href="#" title="Passeios" class="btn-white">
              <span>Ver todos os transfers</span>
              <i class="material-icons">navigate_next</i>
          </a> 
      </div>   
  </div>    
  