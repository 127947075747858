 <div (click)="_click()" class="product-item-two" itemscope itemtype="https://schema.org/Product" [ngClass]="{'empty':produtoFoto == null}" style="background-image: url('{{produto.foto}}')">
  <div class="content">  
      <h3 itemprop="name">{{produto.nome}}</h3> 
      <div class="footer">
          <div class="item">
            <a href="javascript::void();" [title]="produto.titulo" class="btn-one">
              Ver +
            </a>
          </div>
          <div class="item">
            <div class="box-value">
              <produto-item-valor classCustom="item-two" [servico]="produto" [valor]="produto.menor_tarifa"></produto-item-valor>
            </div> 
          </div>  
      </div>    
  </div>    
  <div class="overlay"></div>
  <span style="display:none;" itemprop="sku">{{produto.id}}</span> 
</div>
   