import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsuarioLoginModal } from 'src/app/components/usuario/usuario-login-modal/usuario-login-modal.component';
import { PartialModule } from '../../partial/partial.module';
import { BtnSocialLoginModule } from '../../btn/btn-social-login/btn-social-login.module';
import { LoaderModule } from '../../loader/loader/loader.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '../../directives/directives/directives.module';

@NgModule({
  declarations: [
    UsuarioLoginModal
  ],
  entryComponents: [
    UsuarioLoginModal
  ],
  exports: [
    UsuarioLoginModal
  ],
  imports: [
    CommonModule,
    PartialModule,
    BtnSocialLoginModule,
    LoaderModule,
    ReactiveFormsModule,
    FormsModule,
    DirectivesModule
  ],
  providers: []
})
export class UsuarioLoginModalModule{} 
