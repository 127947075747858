import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'contato-footer',
  templateUrl: './contato-footer.component.html',
  styleUrls: ['./contato-footer.component.scss']
})
export class ContatoFooterComponent implements OnInit {

  constructor() { }

  /**
   * 
   * init
   * 
   */
  ngOnInit(): void {
  }

}
