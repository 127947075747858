<div class="banner-slider" (window:resize)="onResize()" *ngIf="banners.length > 0">
    <div class="banners splide" #slide id="slider-splide">
          <div class="splide__track">
              <ul class="splide__list">
                  <li class="splide__slide item animated fadeIn" *ngFor="let m of banners;let i=index;">
                    <figure *ngIf="!m.link_ativo">
                        <img [src]="m.foto" [alt]="m.titulo"  width="100%" />
                    </figure>
                    <a [href]="m.link" *ngIf="m.link_ativo" [target]="m.link_target">
                        <img [src]="m.foto" [alt]="m.titulo"  width="100%" />
                    </a>
                  </li> 
              </ul>
          </div>    
    </div>  
</div>   


