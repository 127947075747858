<section class="page-home">  
  <modulo posicao="banner-slider" class="wow fadeIn"></modulo>
  <modulos-destaque></modulos-destaque>
  <modulo posicao="newsletter"></modulo> 
  <modulo posicao="trip-advisor"></modulo>   
</section>   

 



