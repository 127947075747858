<div class="module module-frota-about">
      <div class="module-content">
          <div class="container">
              <div class="row">
                  <div class="col-md-4">
                      <div class="box-info">
                          <div class="linha-one _linha"></div>
                          <div class="content-text">
                              <h2>{{data.params.titulo1}} <strong>{{data.params.titulo2}}</strong> {{data.params.titulo3}}</h2>
                              <h3 [innerHtml]="data.params.texto"></h3>
                          </div>
                          <div class="linha-two _linha"></div>  
                      </div>
                  </div>
                  <div class="col-md-8">
                      <div class="content-photos">
                          <div class="photos slider-items" #slider>  
                              <div class="slider-item" *ngFor="let d of data.images">
                                  <div class="item-frota">
                                      <img [src]="d.imagem" width="100%" >
                                  </div>    
                              </div>      
                          </div>
                          <div class="slide-navigate-item template-2 prev" (click)="prev()">
                              <img width="15px" height="15px" src="/images/icons/prev.svg" alt="Prev" />
                          </div>
                          <div class="slide-navigate-item template-2 next" (click)="next()">
                              <img width="15px" height="15px" src="/images/icons/next.svg" alt="Next" />
                          </div> 
                      </div>
                      <div class="content-button">
                          <a routerLink="/frota" title="Nossa frota" class="btn-blue btn-icon">
                              <span>{{'Veja nossa frota' | translate}}</span>
                              <i class="material-icons">navigate_next</i>
                          </a>    
                      </div>    
                  </div>    
              </div>    
          </div>
      </div>
  </div>    