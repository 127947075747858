import { Component, Input, OnInit, ViewChild } from '@angular/core';

declare var $:any;

@Component({
  selector: 'modulo-produto-destaque-three',
  templateUrl: './produto-destaque-three.component.html',
  styleUrls: ['./produto-destaque-three.component.scss']
})
export class ProdutoDestaqueThreeComponent implements OnInit {

  @Input("data") data:any         = {};
  @Input("template") template:any = {};

  @ViewChild("slider") slider: any;
  public products:any = [];
  
  constructor() { }

  /**
   * 
   * Set data
   * 
   */
   setData(){

    this.products = [{
      image: "/images/produtos/oferta/produto-01.jpg",
      name: "Praia de Santa Rita",
      category: "Passeio de Escuna",
      tariff: 250.00
    },{
      image: "/images/produtos/oferta/produto-02.jpg",
      name: "Praia da Conceição",
      category: "Passeio de Escuna",
      tariff: 550.00
    },{
      image: "/images/produtos/oferta/produto-03.jpg",
      name: "Ilha dos Cocos",
      category: "Passeio de Escuna",
      tariff: 350.00
    },{
      image: "/images/produtos/oferta/produto-04.jpg",
      name: "Ilha da Pescaria",
      category: "Passeio de Escuna",
      tariff: 350.00
    },{
      image: "/images/produtos/oferta/produto-01.jpg",
      name: "Praia de Santa Rita",
      category: "Passeio de Escuna",
      tariff: 250.00
    }];

  }
  /***
   * 
   * Inicializa o Slider
   * 
   */
   initSlider(){

    $(this.slider.nativeElement).slick({ 
      infinite: true,
      slidesToShow: 4,
      dots: false, 
      arrows: false,
      centerMode: false,
      centerPadding: '0',
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [{
          breakpoint: 1224,
          settings: {
            slidesToShow: 4,  
          }
      },{
          breakpoint: 1024,
          settings: {
          slidesToShow: 2,
        }
      },
      {
          breakpoint: 768,
          settings: {
              slidesToShow: 1,

          }
      },
      {
          breakpoint: 540,
          settings: {
            slidesToShow: 1,
            centerPadding: '0',
          }
      }
    ]
    
    }); 
    $(this.slider.nativeElement).slick('refresh'); 
    

  }
  /**
   * 
   * Next
   * 
   */
  prev(){ 

    $(this.slider.nativeElement).slick("slickPrev");  

  }
  /**
   * 
   * Next
   * 
   */
  next(){

    $(this.slider.nativeElement).slick("slickNext"); 

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit(): void {
    this.setData();
  }
  /**
   * 
   * After view init
   * 
   */
   ngAfterViewInit(): void {
    this.initSlider();
  }


}
