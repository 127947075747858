<div class="module module-blog-two">
      <div class="module-content">
          <div class="container">
              <div class="row">
                  <div class="col-md-3">
                      <h2>{{data.titulo}}</h2>
                      <h3 *ngIf="data.mostrar_subtitulo">{{data.subtitulo}}</h3>
                  </div>
                  <div class="col-md-9">
                    <div class="blog-items slider-items slick-padding-mobile" #slider>  
                        <div class="slider-item" *ngFor="let d of data.posts">
                            <blog-item [data]="d"></blog-item>  
                        </div>      
                    </div>
                    <div class="slide-navigate-item prev" (click)="prev()"> 
                        <div class="content">
                            <i class="material-icons">navigate_before</i>
                        </div>
                    </div>
                    <div class="slide-navigate-item next" (click)="next()">
                        <div class="content">
                            <i class="material-icons">navigate_next</i>
                        </div>
                    </div>
                    <div class="content-button">
                        <button type="button" class="btn-all" (click)="toBlog()">
                            <span>Ver todos</span>
                            <i class="material-icons">navigate_next</i>
                            <i class="material-icons">navigate_next</i>
                            <i class="material-icons">navigate_next</i>
                        </button>    
                    </div>  
                </div>    
              </div>    
          </div>
      </div>   
  </div>    
  