import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WhatsappComponent } from 'src/app/modulos/whatsapp/whatsapp.component';
import { WhatsappModalComponent } from 'src/app/components/whatsapp/whatsapp-modal/whatsapp-modal.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { LoaderModule } from '../../loader/loader/loader.module';
import { PipeModule } from '../../pipe/pipe.module';

@NgModule({
  declarations: [
    WhatsappComponent,
    WhatsappModalComponent
  ],
  exports:[
    WhatsappComponent,
    WhatsappModalComponent
  ],
  entryComponents:[
    WhatsappModalComponent
  ],
  imports: [
    CommonModule,
    LazyLoadImageModule,
    LoaderModule,
    PipeModule
  ]
})
export class ModuloWhatsappModule { }
