import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'modulos-destaque',  
  templateUrl: './modulos-destaque.component.html',
  styleUrls: ['./modulos-destaque.component.scss']
})
export class ModulosDestaqueComponent implements OnInit {

  public modulos:any = [];

  constructor(
    private data: DataService
  ){}

  /**
   * 
   * Set data
   * 
   */
  setData(){

    this.modulos = this.data.getModulosDestaque();

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.setData();
  }

}
