import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'form-search',
  templateUrl: './form-search.component.html',
  styleUrls: ['./form-search.component.scss']
})
export class FormSearchComponent implements OnInit {

  public dataItem = {
    termo: ""
  }

  constructor(
    private router: Router
  ){}

  /**
   * 
   * Send
   * 
   */
  send(){

    this.router.navigate(["/servicos"],{ queryParams:{termo: this.dataItem.termo}}); 

  }
  /***
   * 
   * Init
   * 
   */
  ngOnInit():void{}

}
