import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'destinos-menu-modulo',
  templateUrl: './destinos-menu-modulo.component.html',
  styleUrls: ['./destinos-menu-modulo.component.scss']
})
export class DestinosMenuModuloComponent implements OnInit {

  @Input("data") data;  
  @Input("template") template = null; 
  @Input("classCustom") classCustom = ""; 
  
  constructor(){}

  /**
   * 
   * Init
   * 
   **/
  ngOnInit():void{}

}
