<div class="modulo contato-suporte-modulo">
      <div class="modulo-titulo" *ngIf="data.mostrar_titulo">
            <h3>{{data.titulo}}</h3>
            <h4 *ngIf="data.mostrar_subtitulo">{{data.subtitulo}}</h4>
      </div> 
      <div class="modulo-content">
            <nav>
                  <ul>
                        <li *ngFor="let d of data.destinos">
                              <a routerLink="/destinos/{{d.apelido}}" alt="{{d.nome}}">
                                    {{d.nome}}
                              </a>
                        </li>      
                  </ul>
            </nav>      
      </div>      
</div>      