<div class="modulo modulo-produto-busca">
      <div class="modulo-title" *ngIf="data.mostrar_titulo"> 
            <div class="container">
                  <h2 class="title">{{data.titulo}}</h2>
                  <h3 class="subtitle" *ngIf="data.mostrar_subtitulo">
                        {{data.subtitulo}}
                  </h3>
            </div>
      </div>
      <div class="modulo-content">
            <div class="container">
                  <form class="form form-custom">
                        <div class="form-group"> 
                              <input type="text" (input)="_search()" class="input-search" placeholder="Passeios, transfer, ingressos e muito mais..." [(ngModel)]="dataItem.termo" name="termo" />
                              <div class="loader" *ngIf="loader">
                                    <div class="lds-dual-ring"></div>
                              </div>      
                        </div>
                  </form>
                  <div class="content-search box-sombra" *ngIf="_data != null">
                        <div class="content" *ngIf="_data.length > 0">
                              <div class="item" *ngFor="let d of _data">
                                    <produto-item-three [data]="d"></produto-item-three>  
                              </div>
                        </div>
                        <div class="content" *ngIf="_data.length == 0">
                              <p>Não econtramos atrações com o termo <strong>"{{dataItem.termo}}"</strong></p>
                        </div>
                  </div>      
            </div>      
      </div>      
</div>      