<div class="input-checkbox">
      <div class="box-input">
            <div class="content" (click)="_click()">
              <i class="material-icons" *ngIf="value === true || value === 'true'">done</i>
            </div>    
      </div>
      <div class="box-label">
          <span>{{label}}</span>
      </div>    
</div>
  
