<a routerlink="/" *ngIf="template == null" (click)="_click()">
  <figure class="logo {{classCustom}}">  
    <img [src]="logo" alt="Logo" [width]="widthLogo" />
  </figure>
</a> 
<a class="default-footer" routerLink="/" *ngIf="template == 'footer'" (click)="_click()">
  <figure class="logo {{classCustom}}"> 
    <img [lazyLoad]="logo" alt="Logo" defaultImage="/images/pixel.png" />
  </figure>
</a> 
  