<div class="modulo trip-advisor-modulo">
      <div class="modulo-content">
            <div class="container">
                  <div class="content">
                        <div class="modulo-title">
                              <h3>{{data.titulo}}</h3>
                              <h4 *ngIf="data.mostrar_subtitulo">{{data.subtitulo}}</h4>
                        </div>
                        <div class="content-trip-advisor">
                              <a [href]="data.params.link" title="Trip Advisor" target="_blank">
                                    <figure>
                                          <img width="100px" src="/images/trip-advisor/{{data.params.imagem}}" alt="Trip Advisor" />
                                    </figure>      
                              </a>      
                        </div>
                  </div>
            </div>
      </div>  
</div>