import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeModule } from './modules/home/home.module';

const routes: Routes = [{ 
  path: '',     
  loadChildren: () => HomeModule 
},{
  path: 'sobre',  
  loadChildren: () => import('src/app/modules/sobre/sobre-module/sobre.module').then(m => m.SobreModule) 
},{
  path: 'minha-conta', 
  loadChildren: () => import('src/app/modules/minha-conta/perfil/perfil.module').then(m => m.PerfilModule) 
},{
  path: 'minha-conta/perfil', 
  loadChildren: () => import('src/app/modules/minha-conta/perfil/perfil.module').then(m => m.PerfilModule) 
},{
  path: 'minha-conta/pedidos', 
  loadChildren: () => import('src/app/modules/minha-conta/pedidos/pedidos.module').then(m => m.PedidosModule) 
},{
  path: 'minha-conta/favoritos',   
  loadChildren: () => import('src/app/modules/minha-conta/favoritos/favoritos.module').then(m => m.FavoritosModule) 
},{
  path: 'carrinho',  
  loadChildren: () => import('src/app/modules/checkout/checkout-carrinho/checkout-carrinho.module').then(m => m.CheckoutCarrinhoModule) 
},{
  path: 'destinos',
  loadChildren: () => import('src/app/modules/destinos/destinos.module').then(m => m.DestinosModule) 
},{
  path: 'assistencia-maragogi',
  loadChildren: () => import('src/app/modules/assistencia/assistencia.module').then(m => m.AssistenciaModule) 
},{
  path: 'assistencia-maragogi/:apelido',
  loadChildren: () => import('src/app/modules/assistencia/assistencia.module').then(m => m.AssistenciaModule) 
},{
  path: 'destinos/:apelido',
  loadChildren: () => import('src/app/modules/destino/destino.module').then(m => m.DestinoModule) 
},{
  path: 'destinos/:apelido_destino/:apelido_categoria',
  loadChildren: () => import('src/app/modules/produto/produtos/produtos.module').then(m => m.ProdutosModule) 
},{
  path: 'destinos/:apelido_destino/:apelido_categoria/:apelido_produto',
  loadChildren: () => import('src/app/modules/produto/produto/produto.module').then(m => m.ProdutoModule)
},{
  path: 'carrinho/pagamento',  
  loadChildren: () => import('src/app/modules/checkout/checkout-pagamento/checkout-pagamento.module').then(m => m.CheckoutPagamentoModule) 
},{
  path: 'carrinho/pagamento/aprovado',
  loadChildren: () => import('src/app/modules/checkout/checkout-pagamento-aprovado/checkout-pagamento-aprovado.module').then(m => m.CheckoutPagamentoAprovadoModule) 
},{
  path: 'servicos',
  loadChildren: () => import('src/app/modules/produto/produtos/produtos.module').then(m => m.ProdutosModule) 
},{
  path: 'servicos/:apelido_categoria', 
  loadChildren: () => import('src/app/modules/produto/produtos/produtos.module').then(m => m.ProdutosModule) 
},{
  path: 'servicos/:apelido_categoria/:apelido_produto',
  loadChildren: () => import('src/app/modules/produto/produto/produto.module').then(m => m.ProdutoModule)
},{
  path: 'pedido/link/:file/aprovado',    
  loadChildren: () => import('src/app/modules/pagafacil/pagafacil-aprovado/pagafacil-aprovado.module').then(m => m.PagafacilAprovadoModule)
},{
  path: 'pedido/link/:file', 
  loadChildren: () => import('src/app/modules/pagafacil/pagafacil/pagafacil.module').then(m => m.PagafacilModule)
},{
  path: 'politicas-de-privacidade',
  loadChildren: () => import('src/app/modules/politica/politica-privacidade/politica-privacidade.module').then(m => m.PoliticaPrivacidadeModule)
},{
  path: 'politicas-de-cancelamento', 
  loadChildren: () => import('src/app/modules/politica/politica-cancelamento/politica-cancelamento.module').then(m => m.PoliticaCancelamentoModule)
},{
  path: 'politicas-de-cookies', 
  loadChildren: () => import('src/app/modules/politica/politica-cookie/politica-cookie.module').then(m => m.PoliticaCookieModule)
},{
  path: 'contato',  
  loadChildren: () => import('src/app/modules/contato/contato.module').then(m => m.ContatoModule) 
},{
  path: 'duvidas-frequentes',     
  loadChildren: () => import('src/app/modules/duvida-frequente/duvida-frequente.module').then(m => m.DuvidaFrequenteModule)  
},{
  path: 'protocolos',       
  loadChildren: () => import('src/app/modules/pages/protocolo-page/protocolo-page.module').then(m => m.ProtocoloPageModule)  
},{
  path: 'grupos-e-eventos',      
  loadChildren: () => import('src/app/modules/pages/grupos-e-eventos-page/grupos-e-eventos-page.module').then(m => m.GruposEEventosPageModule)  
},{
  path: 'blog',      
  loadChildren: () => import('src/app/modules/pages/blog/blog-page/blog-page.module').then(m => m.BlogPageModule)  
},{
  path: 'blog/:categoria_apelido',      
  loadChildren: () => import('src/app/modules/pages/blog/blog-categoria-page/blog-categoria-page.module').then(m => m.BlogCategoriaPageModule)  
},{
  path: 'blog/:categoria_apelido/:apelido',      
  loadChildren: () => import('src/app/modules/pages/blog/blog-post-page/blog-post-page.module').then(m => m.BlogPostPageModule)  
},{
  path: 'frota',
  loadChildren: () => import('src/app/modules/pages/frota/frota-page/frota-page.module').then(m => m.FrotaPageModule) 
},{
  path: 'trabalhe-conosco',
  loadChildren: () => import('src/app/modules/pages/trabalhe-conosco-page/trabalhe-conosco-page.module').then(m => m.TrabalheConoscoPageModule) 
},{    
  path: '**',
  pathMatch: 'full', 
  redirectTo: ''
}];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
