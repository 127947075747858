import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProdutoEmbarcacaoItemComponent } from '../../../components/produto/produto-embarcacao-item/produto-embarcacao-item.component';
import { PartialModule } from '../../partial/partial.module';



@NgModule({
  declarations: [ProdutoEmbarcacaoItemComponent],
  exports: [ProdutoEmbarcacaoItemComponent],
  imports: [
    CommonModule,
    PartialModule
  ]
})
export class ProdutoEmbarcacaoItemModule { }
