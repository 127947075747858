<div class="module">
      <div class="module-title" *ngIf="data.mostrar_titulo">
            <div class="container">
                  <h2 *ngIf="data.mostrar_titulo" class="title">{{data.titulo}}</h2>
                  <h3 *ngIf="data.mostrar_subtitulo" class="subtitle">{{data.subtitulo}}</h3>
            </div>
      </div>
      <div class="module-content">
            <div class="container">
                  <div class="products">
                        <div class="header">
                              <ul>
                                    <li *ngFor="let d of data.data">
                                          <a href="javascript::void();" [style.border-color]="itemActive == d.id ? color : null" [style.color]="itemActive == d.id ? color : null" [title]="d.titulo" [ngClass]="{'active': itemActive == d.id}" (click)="setActive(d.id)">
                                                {{d.titulo}}
                                          </a>    
                                    </li>
                              </ul>
                        </div> 
                        <div class="content">
                              <div class="products-content" *ngFor="let d of data.data">
                                    <div class="row row-content animated slideInRight" *ngIf="itemActive == d.id">
                                          <div class="col-md-3 reverse">
                                                <div class="box box-info">
                                                      <div class="content">
                                                          <h3>{{d.titulo}}</h3>
                                                          <p *ngIf="d.texto_abreviado != null">{{d.texto_abreviado}}</p>
                                                          <p *ngIf="d.id == 1000">As melhores promoções para você.</p>
                                                          <a [style.border-color]="color" routerLink="/servicos/{{d.apelido}}" title="Ver todos" class="btn-one btn-icon desktop">
                                                              <span [style.color]="color">Ver todos</span>
                                                              <div class="icon">
                                                                  <i [style.color]="color" class="material-icons">
                                                                      navigate_next
                                                                  </i>
                                                                  <i [style.color]="color" class="material-icons">
                                                                      navigate_next
                                                                  </i>
                                                                  <i [style.color]="color" class="material-icons">
                                                                      navigate_next
                                                                  </i>
                                                              </div>
                                                          </a>  
                                                      </div>  
                                                </div>  
                                          </div>
                                          <div class="col-md-9">
                                                <div class="box">
                                                      <div class="products-list slider-items" #slider>
                                                          <div class="slider-item item" *ngFor="let p of d.produtos">
                                                            <produto-item-two [data]="p"></produto-item-two>
                                                          </div>
                                                      </div> 
                                                      <div class="slide-navigate-item next" (click)="next()">
                                                          <div class="content">
                                                              <i class="material-icons">navigate_next</i>
                                                          </div>
                                                      </div> 
                                                </div>  
                                                <div class="box box-info">
                                                      <a routerLink="/servicos/{{d.apelido}}" title="Ver todos" class="btn-one btn-icon mobile">
                                                            <span>Ver todos</span>
                                                            <div class="icon">
                                                                <i class="material-icons">
                                                                    navigate_next
                                                                </i>
                                                                <i class="material-icons">
                                                                    navigate_next
                                                                </i>
                                                                <i class="material-icons">
                                                                    navigate_next
                                                                </i>
                                                            </div>
                                                      </a>  
                                                </div>      
                                          </div>
                                    </div>
                              </div>
                        </div>      
                  </div>
            </div>   
      </div>  
</div>      