import { Directive, ElementRef } from '@angular/core';
import { DataService } from '../services/data.service';

@Directive({
  selector: '[bg-2]'
})
export class Bg2Directive {

  public configuracao = null;

  constructor(
    private el: ElementRef,
    private data: DataService
  ){

  }
  /**
   * 
   * Set
   * 
   */
  set(){

    this.el.nativeElement.style.backgroundColor = this.configuracao.cor_2; 

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit(): void {
    this.configuracao = this.data.getConfiguracao();
    this.set();
  }

}
