<section class="modulo-loader {{classCustom}}">
    <div class="loader-wrap">
      <div class="content">
        <div class="loader-content">  
          <div class="lds-ripple"><div></div><div></div></div>
        </div>
        <span class="message">{{message}}</span> 
      </div>  
    </div>
    <div class="overlay"></div> 
</section>
    