import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

declare var $:any;

@Component({
  selector: 'blog-destaque',
  templateUrl: './blog-destaque.component.html',
  styleUrls: ['./blog-destaque.component.scss']
})
export class BlogDestaqueComponent implements OnInit {

  @Input("data") data:any         = null;
  @Input("template") template:any = null;

  @ViewChild("slider") slider: any;
 
  constructor(
    private router: Router
  ) { }

  /***
   * 
   * Inicializa o Slider
   * 
   */
   initSlider(){

    $(this.slider.nativeElement).slick({ 
      infinite: true,
      slidesToShow: 3,
      dots: false, 
      arrows: false,
      centerMode: true,
      centerPadding: '20px',
      responsive: [{
          breakpoint: 1224,
          settings: {
            slidesToShow: 3,  
          }
      },{
          breakpoint: 1024,
          settings: {
          slidesToShow: 2,
        }
      },
      {
          breakpoint: 768,
          settings: {
              slidesToShow: 1,

          }
      },
      {
          breakpoint: 540,
          settings: {
            slidesToShow: 1,
            centerPadding: '0',
          }
      }
    ]
    
    }); 
    $(this.slider.nativeElement).slick('refresh'); 
    

  }
  /**
   * 
   * Next
   * 
   */
  prev(){ 

    $(this.slider.nativeElement).slick("slickPrev");  

  }
  /**
   * 
   * Next
   * 
   */
  next(){

    $(this.slider.nativeElement).slick("slickNext"); 

  }
  /**
   * 
   * To blog
   * 
   */
  toBlog(){

    this.router.navigateByUrl("blog");  

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit(): void {
    
  }
  /**
   * 
   * After view init
   * 
   */
   ngAfterViewInit(): void {
    this.initSlider();
  }

}
