import { Component, OnInit, Input, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { Router } from '@angular/router';
import { GtagService } from 'src/app/services/gtag.service';

@Component({
  selector: 'modulo-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.scss']
})
export class ContatoComponent implements OnInit,AfterViewInit {
  
  @Input("data") data;  
  @Input("template") template = null; 
  @Input("classCustom") classCustom = ""; 
  @ViewChild("messageContato") messageContato: ElementRef;
  @ViewChild("telefoneNumero") telefoneNumero: ElementRef;
  public loader = false;
  public dataItem:any = {}; 
  public message = { 
    data: "",
    class: "",
    show: false
  }; 
  public prefixo = "web";
  public text    = "";
  public numero  = null;

  constructor(
    private app: AppService,
    public route: Router,
    public gtag: GtagService
  ) { 
    this.initDataItem();
  }
  
  /**
   * 
   * Event google
   * 
   */
  _ligacao(){

    this.gtag.clickBotaoLigacao();

  }
  /**
   * 
   * Envia o contato por email
   * 
   * 
   */
  send(){

    /*try{

      this.loader        = true;
      this.message.show  = false;

      this.ModulosService.sendContato(this.dataItem).subscribe(response => {

        this.loader = false;

        switch(response.status){
          case 1:

            this.initDataItem();
            this.message.data  = "Sua mensagem foi enviada! Em breve entraremos em contato.";
            this.message.class = "alert alert-success";
            this.message.show  = true;

          break;
          case 0:

            let errors = this.AppService.getErrors([response.data]);
    
            this.message.data  = errors;
            this.message.class = "alert alert-warning";
            this.message.show  = true;

          break;
          default:

            this.message.data  = "Houve um erro interno. Se o erro permanecer informe nossa equipe.";
            this.message.class = "alert alert-danger";
            this.message.show  = true;

          break;
        }

      }, (err) =>{

        this.loader = false;
        this.message.data  = "Houve um erro interno. Se o erro permanecer informe nossa equipe.";
        this.message.class = "alert alert-danger";
        this.message.show  = true;

      });      

    }catch(e){

    }*/

  }
  /**
   * 
   * Open
   * 
   */
  openWhatsApp(){

    if(this.text !== "" && this.text !== null){
      window.open("https://"+this.prefixo+".whatsapp.com/send?phone="+this.numero+"&text="+this.text,"__blank");
    }else{
      window.open("https://"+this.prefixo+".whatsapp.com/send?phone="+this.numero,"__blank"); 
    }  

  }
  /**
   * 
   * Inicializa o dataItem
   * 
   */
  initDataItem(){

    this.dataItem = {
      nome: "",
      email: "",
      telefone_ddd: "",
      telefone_numero: "",
      assunto: "",
      mensagem: ""
    }

  }  
  onInputDDD($event){

    if(this.dataItem.telefone_ddd.length == 2){
      this.telefoneNumero.nativeElement.focus(); 
    }

  } 
  /**
   * 
   * Seta o número
   * 
   */
  setNumero(){
    
    let telefone = this.data.params.whatsapp.replace("(","").replace(")","").replace("-","").replace(" ","").replace(".","");
    this.numero = "55"+telefone;


  }
  /**
   * 
   * To contato
   * 
   */
  toContato(){ 

    this.route.navigateByUrl("/contato");   

  }
  /**
   * 
   * Inicializa s Funções
   * 
   * 
   */
  ngOnInit(){
    this.setNumero();  
  }
  /**
   * 
   * Inicializa as Funções depois de carregar
   * 
   */
  ngAfterViewInit(){}

}
