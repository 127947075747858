<div class='lg-list'>
    <div class="selected" (click)="_show()"> 
        <img src='/images/icons/linguagem/{{codigo}}.svg' alt="Português" width="25px" />
        <i class="material-icons">expand_more</i>
    </div> 
    <div class="menu-select animated slideInDown" [hidden]="!show"> 
        <div class="content">
            <div class="item" *ngFor="let i of list" (click)="_select(i)">
                <img src='/images/icons/linguagem/{{i.codigo}}.svg' width="25px" height="25px" />
                <span>{{i.titulo}}</span>
            </div>    
        </div>
    </div>
</div>   
<loader *ngIf="loader"></loader> 