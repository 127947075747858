import { Component, Input, OnInit } from '@angular/core';
import { FacebookLoginProvider, SocialAuthService, SocialAuthServiceConfig } from 'angularx-social-login';
import { UsuarioService } from 'src/app/services/usuario.service';
import { StorageService } from 'src/app/services/storage.service';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { GtagService } from 'src/app/services/gtag.service';

@Component({
  selector: 'btn-facebook-login',
  templateUrl: './btn-facebook-login.component.html',
  styleUrls: ['./btn-facebook-login.component.scss']
})
export class BtnFacebookLoginComponent implements OnInit {
  
  @Input("usuarioService") usuarioService:any = null; 
  public usuario = null;
  public loader  = false;

  constructor(
    private authService: SocialAuthService,
    private storage: StorageService,
    private api: ApiService,
    private app: AppService,
    private gtag: GtagService
  ){ 


  }
  /**
   * 
   * Open
   * 
   */
  _open(){

    try{

      this.gtag.clickFacebook();

      this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(user => {
          
        if(user.provider == "FACEBOOK"){ 
          
          this.usuario = user; 
          
          this.usuario = {
            id: user.id,
            nome: user.name,
            email: user.email,
            foto: user.photoUrl,
            primeiro_nome: user.firstName,
            ultimo_nome: user.lastName,
            provider: "FACEBOOK" 
          };
          
          this.loader = true;
          this.api.loja().login_social(this.usuario).subscribe(response => {

            this.loader = false;  

            if(response.status == 1){
              
              this.app.emitTest.emit(null);
              this.usuarioService.emitUsuarioLogin.emit(this.usuario); 
              this.storage.setUsuario(this.usuario);
            
            }else{

              this.loader = false;
              this.app.info("Houve um erro. Tente novamente ou entre em contato com nossa equipe.","danger");

            }

          },(response)=>{

            this.loader = false;
            this.app.info("Houve um erro. Tente novamente ou entre em contato com nossa equipe.","danger");

          });  

        } 

      },(response) => {

        //this.app.info("Houve um erro. Tente novamente ou entre em contato com nossa equipe.","danger");
  
      });

    }catch(e){

      alert(e.message);

    }

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit():void{

  }

}
