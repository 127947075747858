import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'produto-item-three',
  templateUrl: './produto-item-three.component.html',
  styleUrls: ['./produto-item-three.component.scss']
})
export class ProdutoItemThreeComponent implements OnInit {

  @Input("data") produto:any        = null;
  @Input("categoria") categoria:any = null;
  public link                       = null;   
  public defaultImage               = "/images/produto/sem-foto/default-capa.jpg";
  public beneficios                 = [];

  constructor(
    private route: Router
  ){}

  /**
   * 
   * Click
   * 
   */
   _click(){

    this.route.navigateByUrl(this.link);

  }
  /**
   * 
   * Seta o link para o detalhe do produto
   * 
   */
   setLink(){

      
    if(this.categoria ==  null){
      this.link = "/servicos/"+this.produto.categoria_apelido+"/"+this.produto.apelido;
    }else{
      this.link = "/servicos/"+this.categoria.apelido+"/"+this.produto.apelido;
    }  


  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.setLink();
  }

}
