import { AfterViewChecked, AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

declare var $:any;

@Component({
  selector: 'modulo-produto-destaque-two',
  templateUrl: './produto-destaque-two.component.html',
  styleUrls: ['./produto-destaque-two.component.scss']
})
export class ProdutoDestaqueTwoComponent implements OnInit, AfterViewInit{

  @Input("data") data:any = null;
  @Input("template") template:any = null;
  
  @ViewChild("slider") slider: any;
  public products:any = [];
  public tabSelect = 0;
  public tabs:any = [];
  public showItem = true;

  constructor() { }
  
  /**
   * 
   * Select tab
   * 
   * @param y 
   * 
   */
   selectTab(y:any){

    this.tabSelect = y;
    this.showItem = false;
    
    setTimeout(() => {
      this.initSlider();
    },5); 

    setTimeout(() => {
      this.showItem = true;   
    },20); 

  }
  /***
   * 
   * Inicializa o Slider
   * 
   */
  initSlider(){

    $(this.slider.nativeElement).slick({ 
      infinite: true,
      slidesToShow: 4,
      dots: false, 
      arrows: false,
      centerMode: false,
      centerPadding: '0',
      responsive: [{
          breakpoint: 1224,
          settings: {
            slidesToShow: 4,  
          }
      },{
          breakpoint: 1024,
          settings: {
          slidesToShow: 2,
        }
      },
      {
          breakpoint: 768,
          settings: {
              slidesToShow: 1,

          }
      },
      {
          breakpoint: 540,
          settings: {
            slidesToShow: 1,
            centerPadding: '0',
          }
      }
    ]
    
    }); 
    $(this.slider.nativeElement).slick('refresh'); 
    

  }
  /**
   * 
   * Next
   * 
   */
  prev(){ 

    $(this.slider.nativeElement).slick("slickPrev");  

  }
  /**
   * 
   * Next
   * 
   */
  next(){

    $(this.slider.nativeElement).slick("slickNext"); 

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit(): void {
    this.setTabs();  
  }
  /**
   * 
   * Set tabs
   * 
   */
   setTabs(){

    this.tabs = [{
      id: 1,
      title: "Transfer Regular",
      produtos: []
    },{
      id: 2,
      title: "Transfer Privativo",
      produtos: []
    }];

    for (let index = 0; index < this.tabs.length; index++) {
      
      for (let i = 0; i < this.data.produtos.length; i++) {
        
        if(this.tabs[index].id == this.data.produtos[i].produto_tipo_id){
          this.tabs[index].produtos.push(this.data.produtos[i]);
        }
        
      }
      
    }

  }
  /**
   * 
   * After view init
   * 
   */
   ngAfterViewInit(): void {
    this.initSlider();
  }


}
