import { Pipe, PipeTransform } from '@angular/core';
import { DataService } from '../services/data.service';
import { TranslateService } from '../services/translate.service';

@Pipe({
  name: 'formatDuracao' 
})
export class FormatDuracaoPipe implements PipeTransform { 

  public linguagem  = "pt-BR";

  constructor(
    private translate: TranslateService,
    private data: DataService
  ){

    this.linguagem = this.data.getLinguagem();

  }
  /***
   * 
   * 
   * @param value 
   * @param args 
   * @returns 
   * 
   **/
  transform(value: any, args?: any): any {
    
    let data     = value.split(":");
    let horas    = parseInt(data[0]);
    let minutos  = parseInt(data[1]);
    let _horas   = "horas";
    let _hora    = "hora";
    let _e       = "e";
    let _minutos = "minuto";

    if(this.linguagem != "pt-BR"){

      _horas = this.translate.getTraducao(_horas,this.linguagem);

    }
    
    if(horas > 1 && minutos == 0){
      return parseInt(data[0]) + ` ${_horas}`; 
    }else if(horas == 1 && minutos == 0){
      return parseInt(data[0]) + ` ${_hora}`;
    }else if(horas > 1 && minutos > 0){
      return parseInt(data[0]) + ` ${_horas} ${_e} `+minutos+` ${_minutos}`+`(s)`;
    }else{
      return parseInt(data[0]) + ` ${_hora} ${_e} `+minutos+` ${_minutos}`+`(s)`;
    }
  
  }

}
