<div class="module-newsletter">
  <div class="module-title" *ngIf="data.mostrar_titulo">
    <div class="container">
      <h3>{{data.titulo}}</h3>
      <span *ngIf="data.mostrar_subtitulo">{{data.subtitulo}}</span>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="content-form">
          <form class="form-custom" (submit)="send()">
              <div class="item">
                <label>Digite o seu nome:</label>
                <input type="text" [(ngModel)]="dataItem.nome" name="nome" placeholder="{{'Informe seu nome' | translate}}" />
              </div>    
              <div class="item item-email">  
                <label>Digite o seu e-mail:</label>
                <input type="text" [(ngModel)]="dataItem.email" name="email" placeholder="{{'Informe seu e-mail' | translate}}" />
              </div>
              <div class="item">
                <button class="btn-custom"> 
                  <span>Enviar</span>
                </button> 
              </div>
          </form>
        </div> 
      </div>
    </div> 
  </div>  
</div>  
<loader *ngIf="loader"></loader>
