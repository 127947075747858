import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'produto-classificacao-item',
  templateUrl: './produto-classificacao-item.component.html',
  styleUrls: ['./produto-classificacao-item.component.scss']
})
export class ProdutoClassificacaoItemComponent implements OnInit {

  @Input("data") data:any = null;

  constructor() { }

  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}

}
