import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'whatsapp-modal',
  templateUrl: './whatsapp-modal.component.html',
  styleUrls: ['./whatsapp-modal.component.scss']
})
export class WhatsappModalComponent implements OnInit {

  public params  = null;
  public text    = null;
  public numero  = null;
  public text2   = null;
  public numero2 = null; 
  public prefixo = "web";

  constructor(
    public dialog: MatDialogRef<WhatsappModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private deviceService: DeviceDetectorService
  ){

    this.params = this.data.params;

  }
  /**
   * 
   * Open
   * 
   */
   open(number=1){

    let text   = "";
    let numero = "";

    if(number == 1){
      text   = this.text;
      numero = this.numero; 
    }else{
      text   = this.text2;
      numero = this.numero2;
    }


    if(text !== "" && text !== null){ 
      window.open("https://"+this.prefixo+".whatsapp.com/send?phone="+numero+"&text="+text,"__blank");
    }else{
      window.open("https://"+this.prefixo+".whatsapp.com/send?phone="+numero,"__blank");
    }  

  }
  /**
   * 
   * Seta o número
   * 
   */
   setNumero(){
    
    this.numero  = "55"+this.data.params.telefone.replace("(","").replace(")","").replace("-","").replace(" ","");
    this.numero2 = "55"+this.data.params.telefone2.replace("(","").replace(")","").replace("-","").replace(" ","");
   
  }
  /**
   * 
   * Seta a mensage
   * 
   */
  setMessage(){

    if(typeof(this.data.params.texto) != "undefined"){
      this.text = this.data.params.texto;
    }

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.setNumero();
    this.setMessage();
    this.prefixo = this.deviceService.isDesktop() ? "web" : "api";
  }

}
